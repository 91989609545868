function backupData(projects) {
    console.log("Backup Clicked");

    let content = JSON.stringify(projects); // , null, 4);
    const a = document.createElement("a");
    const file = new Blob([content], { type: 'text/plain' });
    a.href = URL.createObjectURL(file);
    a.download = 'planted-projects.json';
    a.click();
}

function restoreData(handleData) {
    console.log("Restore Clicked");
    var input = document.createElement('input');
    input.type = 'file';
    input.accept = '.json';

    input.onchange = e => {
        var file = e.target.files[0];
        const reader = new FileReader();
        reader.addEventListener('load', (event) => {
            console.log(event.target.result);
            handleData(event.target.result);
        });
        reader.readAsText(file);
    }

    input.click();
}

export { backupData, restoreData };