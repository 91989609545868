import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListIcon from "@mui/icons-material/List";
import SwapIcon from '@mui/icons-material/SwapHoriz';
import TryIcon from '@mui/icons-material/Check';
import moment from 'moment';
import { Divider } from '@mui/material';



export default function RevisionsList({ revisions, try_callback, switch_callback }) {

    const [showRevisions, setShowRevisions] = React.useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setShowRevisions(open);
    };

    const list = () => (
        <Box
            sx={{ width: 300 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                {revisions.map((data, entry) => (
                    <ListItem key={entry} style={{ backgroundColor: 'grey.100' }}>
                        <ListItemText
                            primary={
                                <>
                                    Version {revisions.length - entry} - {moment(data.ts).format('M/D/YYYY HH:mm:ssa')}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<SwapIcon />}
                                        style={{ margin: 10 }}
                                        onClick={switch_callback}
                                    >Switch</Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<TryIcon />}
                                        style={{ margin: 10 }}
                                        onClick={() => try_callback(data.data)}
                                    >Try</Button>
                                    <Divider />
                                </>
                            }
                        />
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                startIcon={<ListIcon />}
                style={{ margin: 10 }}
                onClick={toggleDrawer(true)}
            >
                Revisions
            </Button>
            <React.Fragment key='right'>
                <Drawer
                    anchor='right'
                    open={showRevisions}
                    onClose={toggleDrawer(false)}
                >
                    {list()}
                </Drawer>
            </React.Fragment>
        </>
    );
}