import "../css/App.css";
import React, { useState } from "react";
import { Link, Prompt, useParams } from "react-router-dom";

import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import PlayArrow from "@mui/icons-material/PlayArrow";
import SaveIcon from "@mui/icons-material/Save";
import ShareIcon from "@mui/icons-material/Share";
import CloseIcon from "@mui/icons-material/Close";
import { Alert } from "@mui/material";
import TextField from "@mui/material/TextField";

import PlantUmlEncoder from "plantuml-encoder";

import useLocalStorage from "../utils/LocalStorage";
import AreYouSureDialog from "../utils/AreYouSureDialog";

import TopBar from "../components/TopBar";
import ThemeSelector from "../components/ThemeSelector";

import RevisionsList from "../components/RevisionsList";
import ShareDialog from "../utils/ShareDialog";

function App() {
  let params = useParams();
  const projectId = params.projectId;
  const [database, setDatabase] = useLocalStorage("database");
  const [isBlocking, setIsBlocking] = useState(false);

  const [sharePermissionDialogOpen, setSharePermissionDialogOpen] =
    useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  if (typeof database.projects[projectId] === "undefined") {
    database.projects[projectId] = {
      projectId: projectId,
      createDate: Date.now(),
      updateDate: null,
      type: "Sequence Diagram",
      revisions: [],
      name: "",
      data: PlantUmlEncoder.encode(
        "@startuml\nBob->Alice: Hello World\n@enduml"
      ),
      unsavedData: PlantUmlEncoder.encode(
        "@startuml\nBob->Alice: Hello World\n@enduml"
      ),
    };
    setDatabase(database);
  } else if (typeof database.projects[projectId].unsavedData === "undefined") {
    database.projects[projectId].unsavedData =
      database.projects[projectId].data;
  }

  const [data, setData] = useState(
    `${PlantUmlEncoder.decode(database.projects[projectId].unsavedData)}`
  );

  const plantBaseUrl = "https://plantuml.com/plantuml/png/";
  // const [data, setData] = useState(
  //   PlantUmlEncoder.decode(projects[projectId].data)
  // );
  const [image, setImage] = useState(
    plantBaseUrl + database.projects[projectId].unsavedData
  );

  const [title, setTitle] = useState(database.projects[projectId].name);

  const [theme, setTheme] = useState(
    typeof database.projects[projectId].theme === "undefined"
      ? "null"
      : database.projects[projectId].theme
  );

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const autoSaveData = () => {
    console.log("Autosaving data!");
    database.projects[projectId].unsavedData = PlantUmlEncoder.encode(data);
    database.projects[projectId].name = title;
    database.projects[projectId].theme = theme;
    setDatabase(database);
  };

  const shareDiagram = () => {
    setSharePermissionDialogOpen(true);
  };

  const createShareURL = () => {
    setShareDialogOpen(true);
  };

  const saveData = () => {
    if (database.projects[projectId].data === data) {
      setAlertMessage("Diagram unchanged. No revision created");
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 3000);
      return;
    }
    // don't use revisions.length as we may allow deleting of versions
    const revision = {
      data: PlantUmlEncoder.encode(data),
      ts: Date.now(),
    };
    database.projects[projectId].name = title;
    database.projects[projectId].theme = theme;
    database.projects[projectId].updateDate = Date.now();
    database.projects[projectId].revisions.push(revision);
    database.projects[projectId].data = PlantUmlEncoder.encode(data);
    database.projects[projectId].unsavedData = PlantUmlEncoder.encode(data);
    setDatabase(database);
    setIsBlocking(false);
  };

  const updateTitle = (text) => {
    setIsBlocking(true);
    setTitle(text);
    if (text.length === 0) updateDataAndImage(data.replace(/title.*\n/g, ""));
    else
      updateDataAndImage(
        data
          .replace(/title.*\n/g, "")
          .replace(/@startuml\n/, `@startuml\ntitle ${text}\n`)
      );
  };

  const updateDataAndImage = (updatedData) => {
    setData(updatedData);
    setImage(plantBaseUrl + PlantUmlEncoder.encode(updatedData));
  };

  const changeTheme = (e) => {
    setIsBlocking(true);
    let newTheme = e.target.value;
    setTheme(newTheme);
    if (newTheme === "null")
      updateDataAndImage(data.replace(/!theme.*\n/g, ""));
    else
      updateDataAndImage(
        data
          .replace(/!theme.*\n/g, "")
          .replace(/@startuml\n/, `@startuml\n!theme ${newTheme}\n`)
      );
  };

  const try_callback = (data) => {
    updateDataAndImage(PlantUmlEncoder.decode(data));
  };

  return (
    <>
      <Grid container spacing={2}>
        <TopBar />
        <Grid
          container
          style={{
            marginTop: 10,
            paddingLeft: 60,
            paddingRight: 60,
          }}
        >
          <Grid xl={12} style={{ width: "100%", marginBottom: 20 }}>
            <TextField
              id="outlined-fixed"
              label="Diagram Title"
              placeholder="Title for diagram"
              value={title}
              onChange={(event) => updateTitle(event.target.value)}
              style={{ marginBottom: 20 }}
            />
            <ThemeSelector handleChange={changeTheme} selectedTheme={theme} />
            <Grid style={{ float: "right" }}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<ShareIcon />}
                style={{ margin: 10 }}
                onClick={shareDiagram}
              >
                Share
              </Button>
              <Link to="/">
                <Tooltip title="Close diagram and return to home page">
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<CloseIcon />}
                    style={{ margin: 10 }}
                  >
                    Close
                  </Button>
                </Tooltip>
              </Link>
            </Grid>
            <TextField
              id="outlined-multiline-fixed"
              label="Sequence diagram UML"
              multiline
              value={data}
              maxRows={20}
              style={{ width: "100%" }}
              onChange={(event) => {
                setIsBlocking(true);
                setData(event.target.value);
              }}
            />
          </Grid>
          <Grid xl={12} style={{ width: "100%", marginBottom: 20 }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<PlayArrow />}
              onClick={() => {
                autoSaveData(data);
                setImage(plantBaseUrl + PlantUmlEncoder.encode(data));
              }}
            >
              Update
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              style={{ margin: 10 }}
              onClick={saveData}
            >
              Save
            </Button>
            <RevisionsList
              revisions={[...database.projects[projectId].revisions].reverse()}
              try_callback={try_callback}
            />
          </Grid>
          <Grid style={{ width: "100%" }}>
            <img src={image} alt="Diagram" style={{ maxWidth: "100%" }} />
          </Grid>
        </Grid>
      </Grid>
      {showAlert ? (
        <Alert
          variant="filled"
          severity="warning"
          onClose={() => {
            setShowAlert(false);
          }}
          style={{ width: "auto", position: "absolute", top: 10, right: 10 }}
        >
          {alertMessage}
        </Alert>
      ) : (
        ""
      )}
      <Prompt
        when={isBlocking}
        message={
          "You have unsaved changes. Click 'Cancel' and click the 'Save' button to ensure that the data is not lost."
        }
      />
      <AreYouSureDialog
        handleNo={() => setSharePermissionDialogOpen(false)}
        handleYes={() => {
          setSharePermissionDialogOpen(false);
          createShareURL();
        }}
        dialogOpen={sharePermissionDialogOpen}
        title="Share data?"
        text="By clicking the Yes button you are allowing this webpage to store data on a server endpoint. 
        Data will be stored for 30 minutes and will be erased after that period. Anybody with the share URL will be 
        able to access the data during that time<br/><br/><br/>Are you sure?"
      />
      {shareDialogOpen && (
        <ShareDialog
          dialogOpen={shareDialogOpen}
          diagramTitle={title}
          diagramData={data}
          title="Share URL"
          onClose={() => setShareDialogOpen(false)}
        ></ShareDialog>
      )}
    </>
  );
}

export default App;
