import { Grid, AppBar, Toolbar, IconButton } from '@mui/material';
import HomeIcon from "@mui/icons-material/Home";
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';

export default function TopBar() {
    return (
        <Grid container sx={{ flexGrow: 1 }}>
            <AppBar
                position="static"
                style={{ alignItems: "left", verticalAlign: "middle" }}
            >
                <Toolbar>
                    <Link to="/">
                        <IconButton
                            color="primary"
                            aria-label="home"
                            sx={{ mr: 2 }}
                            style={{ backgroundColor: "white", marginLeft: 10, marginTop: 10 }}
                        >
                            <HomeIcon />
                        </IconButton>
                    </Link>
                    <Typography mt={2} style={{ fontSize: 20, fontWeight: 'bolder' }}>
                        Planted - a better way to use plantUML
                    </Typography>
                </Toolbar>
            </AppBar>
            <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0, float: 'right', backgroundColor: "rgba(192, 192, 192, 0.5)" }}>
                <div style={{ width: "100%" }}>
                    <img width="200" style={{ float: 'right' }} alt="All your pixels are round to us!" src="/pixelround.png" />
                </div>
            </AppBar>
        </Grid>
    );
}