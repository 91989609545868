import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

export default function ThemeSelector({
    selectedTheme,
    handleChange
}) {

    return (
        <FormControl style={{ marginLeft: 10 }}>
            <InputLabel id="theme-select-label">Theme</InputLabel>
            <Select
                labelId="theme-select-label"
                id="theme-select"
                value={selectedTheme}
                label="Theme"
                onChange={handleChange}
            >
                <MenuItem value="null">--default--</MenuItem>
                <MenuItem value="amiga">amiga</MenuItem>
                <MenuItem value="aws-orange">aws-orange</MenuItem>
                <MenuItem value="black-knight">black-knight</MenuItem>
                <MenuItem value="bluegray">bluegray</MenuItem>
                <MenuItem value="blueprint">blueprint</MenuItem>
                <MenuItem value="cerulean-outline">cerulean-outline</MenuItem>
                <MenuItem value="cerulean">cerulean</MenuItem>
                <MenuItem value="crt-amber">crt-amber</MenuItem>
                <MenuItem value="crt-green">crt-green</MenuItem>
                <MenuItem value="cyborg-outline">cyborg-outline</MenuItem>
                <MenuItem value="cyborg">cyborg</MenuItem>
                <MenuItem value="hacker">hacker</MenuItem>
                <MenuItem value="lightgray">lightgray</MenuItem>
                <MenuItem value="mars">mars</MenuItem>
                <MenuItem value="materia-outline">materia-outline</MenuItem>
                <MenuItem value="materia">materia</MenuItem>
                <MenuItem value="metal">metal</MenuItem>
                <MenuItem value="mimeograph">mimeograph</MenuItem>
                <MenuItem value="minty">minty</MenuItem>
                <MenuItem value="plain">plain</MenuItem>
                <MenuItem value="reddress-darkblue">reddress-darkblue</MenuItem>
                <MenuItem value="reddress-darkgreen">reddress-darkgreen</MenuItem>
                <MenuItem value="reddress-darkorange">reddress-darkorange</MenuItem>
                <MenuItem value="reddress-darkred">reddress-darkred</MenuItem>
                <MenuItem value="reddress-lightblue">reddress-lightblue</MenuItem>
                <MenuItem value="reddress-lightgreen">reddress-lightgreen</MenuItem>
                <MenuItem value="reddress-lightorange">reddress-lightorange</MenuItem>
                <MenuItem value="reddress-lightred">reddress-lightred</MenuItem>
                <MenuItem value="sandstone">sandstone</MenuItem>
                <MenuItem value="silver">silver</MenuItem>
                <MenuItem value="sketchy-outline">sketchy-outline</MenuItem>
                <MenuItem value="sketchy">sketchy</MenuItem>
                <MenuItem value="spacelab">spacelab</MenuItem>
                <MenuItem value="spacelab-white">spacelab-white</MenuItem>
                <MenuItem value="superhero-outline">superhero-outline</MenuItem>
                <MenuItem value="superhero">superhero</MenuItem>
                <MenuItem value="toy">toy</MenuItem>
                <MenuItem value="united">united</MenuItem>
                <MenuItem value="vibrant">vibrant</MenuItem>
            </Select>
        </FormControl >
    );
}