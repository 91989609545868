import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const helpContent = [
    {
        label: 'What is this site?',
        details:
            <Typography>
                I created this site to allow me to maintain revisions of diagrams I make when I explain flows to my customers.
                This site simply uses the freely available <a href="http://plantuml.com">PlantUML</a>
                diagraming tool. If you are plantuml administrator, please contact me at <a href="mailto:support@pixelround.com">support@pixelround.com</a>
            </Typography>
    },
    {
        label: 'Privacy',
        details:
            <Typography>
                We value your privacy tremendously. This site does not store any data from your browser. We do not ask you to login, we don't
                need to know who you are to provide you an amazing experience and a great utility tool.,
            </Typography>
    },
    {
        label: 'Local Data',
        details:
            <Typography>
                This website does not have any server backed storage. The data is stored as JSON in the local storage of this browser. In the Chrome
                browser you can right click the browser window, click on Inspect, switch over to Application, and over there on the left you can see
                "Local Storage". That is where the data is stored for this site. Please remember that this data is only stored in your browser.
                If you clear your browsers local storage this data will be lost. It will be a good idea to backup this data to your local computer.,
            </Typography>
    },
    {
        label: 'Tracking',
        details:
            <Typography>
                Other than loading the HTML, JS and CSS assets from planted.pixelround.com this website does not track you.
                There are no cookies dropped in your browser and there is no customized experience.,
            </Typography>
    },
    {
        label: 'About Us',
        details:
            <Typography>
                We are a husband and wife team of engineers, architects and parents. We love to do this kind of utility software and
                are gainfully employed. We would love to help you on your projects for a reasonable price. Please contact us
                at <a href="mailto:contact@pixelround.com">contact@pixelround.com</a> for any business inquires,
            </Typography>
    },
];

function HelpContent() {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = helpContent.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
            <Typography sx={{ textAlign: 'center' }} variant="h4">Planted by PixelRound - Help!</Typography>
            <Paper
                square
                elevation={0}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: 50,
                    pl: 2,
                    bgcolor: 'background.default',
                }}
            >
                <Typography variant="h5">{helpContent[activeStep].label}</Typography>
            </Paper>
            <AutoPlaySwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >
                {helpContent.map((step, index) => (
                    <div key={index}>
                        {Math.abs(activeStep - index) <= 2 ? (
                            <div
                                style={{
                                    height: 255,
                                    display: 'block',
                                    maxWidth: 400,
                                    overflow: 'auto',
                                    width: '98%',
                                    padding: '5px',
                                    backgroundColor: '#759eb8',
                                    color: '#fceade',
                                }}
                                variant='body1'
                            >
                                {step.details}
                            </div>
                        ) : null}
                    </div>
                ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <Button
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                    >
                        Next
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                        ) : (
                            <KeyboardArrowRight />
                        )}
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                        ) : (
                            <KeyboardArrowLeft />
                        )}
                        Back
                    </Button>
                }
            />
        </Box>
    );
}

export default HelpContent;