import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import CopyIcon from "@mui/icons-material/CopyAll";

function ensureTrailingSlash(str) {
  return str.endsWith("/") ? str : str + "/";
}

function urlSafeBase64Encode(input) {
  // Convert input to Base64
  const base64 = btoa(input);

  // Make it URL-safe
  const urlSafeBase64 = base64
    .replace(/\+/g, "-") // Replace '+' with '-'
    .replace(/\//g, "_") // Replace '/' with '_'
    .replace(/=+$/, ""); // Remove padding '='

  return urlSafeBase64;
}

export default function ShareDialog({
  diagramTitle,
  diagramData,
  dialogOpen,
  onClose,
}) {
  const [url, setUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleFetch = async () => {
      setLoading(true);
      setError(null);

      try {
        // Example payload for the POST request
        const payload = {
          title: urlSafeBase64Encode(diagramTitle),
          message: urlSafeBase64Encode(diagramData),
        };

        console.log(process.env.REACT_APP_PLANTED_SERVER_ROOT_URL);
        console.log(process.env.REACT_APP_PLANTED_API_SERVER_URL);
        const response = await fetch(
          ensureTrailingSlash(process.env.REACT_APP_PLANTED_API_SERVER_URL) +
            "shares",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        let shareUrl =
          ensureTrailingSlash(process.env.REACT_APP_PLANTED_SERVER_ROOT_URL) +
          "share?d=" +
          urlSafeBase64Encode(result.shareUrl);
        setUrl(shareUrl);
      } catch (err) {
        console.log(err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    handleFetch();
  }, [diagramData, diagramTitle]);

  const handleCopy = () => {
    if (url) {
      navigator.clipboard.writeText(url).then(() => {
        console.log("Copied to clipboard!");
      });
    }
  };

  return (
    <Dialog
      open={dialogOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Share URL</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div style={{ padding: "20px", maxWidth: "600px", margin: "auto" }}>
            {loading && <p>Loading...</p>}
            {error && <p style={{ color: "red" }}>Error: {error}</p>}
            {url && (
              <div
                style={{
                  marginTop: "20px",
                  padding: "10px",
                  border: "1px solid #ddd",
                  borderRadius: "4px",
                  backgroundColor: "#f9f9f9",
                }}
              >
                <p style={{ fontWeight: "bold" }}>
                  Data has been stored on a server ready to be shared with
                  anyone. Click the button below to copy the share URL to
                  clipboard. You can then send the URL to the recipient via
                  email, text, or Slack message
                </p>
                <div
                  style={{
                    overflow: "auto",
                    textAlign: "center",
                  }}
                >
                  {url}
                  <Button
                    variant="outlined"
                    onClick={handleCopy}
                    startIcon={<CopyIcon />}
                  />
                  ⬅ ⬅ ⬅ ⬅
                </div>
              </div>
            )}
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
